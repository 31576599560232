<template>
  <v-container class="d-flex flex-column align-center justify-start pb-8" style="z-index: 2;">
    <v-col cols="12" xl="9" lg="11" md="12" sm="12" xs="12" class="px-0">

      <!-- IMAGE -->
      <div class="d-flex flex-row justify-center align-start flex-wrap full-width">

        <!-- <div class="go-back-button hidden-md-and-down primary--text">
          <v-btn small depressed text @click="$router.go(-1)" class="pl-0 primary--text">
            <v-icon small class="mr-1">mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </div> -->

        <div class="full-width">
          <v-col cols="12" xl="7" lg="9" md="12" sm="12" class="px-4 px-md-0 py-0">
            <v-btn small depressed text @click="$router.go(-1)" class="pl-0 hidden-xs-only">
              <v-icon small class="mr-1 primary--text">mdi-arrow-left</v-icon>
              Back
            </v-btn>
            <v-btn medium depressed text @click="$router.go(-1)" class="pl-0 hidden-sm-and-up">
              <v-icon medium class="mr-1 primary--text">mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </v-col>
        </div>

        <div class="project-image-container">
          <v-col cols="12" xl="8" lg="8" md="12" sm="12" xs="12" style="position: relative;">
            <v-carousel v-model="projectCarousel" v-if="!loading"  class="full-width" height="100%"  hide-delimiter-background :show-arrows-on-hover="!isMobileDevice" hide-delimiters reverse-transition="fade-transition" transition="fade-transition">
              <v-carousel-item v-for="(slide, i) in getAllProjectImages" :key="i" >
                <div class="d-flex flex-row justify-center align-center full-height full-width">
                  <v-img class="project-main-img"  :src="slide.src" :alt="project.title" aspect-ratio="1.7778" contain></v-img>
                </div>
              </v-carousel-item>
              <div class="carousel-delimiter-preview glass-background-3 rounded hidden-xs-only">
                  <div class="preview-item cursor--pointer rounded" :class="[ j === projectCarousel ? '' : 'opacity-3' ]" v-for="(image, j) in getAllProjectImages" :key="j" @click="changeCurrentCarouselItemWithIndex(j)">
                    <v-img class="full-width full-height rounded" :class="[ j === projectCarousel ? 'highlight' : '' ]" :src="image.src" :alt="project.title" aspect-ratio="1.7778" cover></v-img>
                  </div>
              </div>
            </v-carousel>
            <div v-else class="loading-data"></div>
          </v-col>
        </div>

      </div>
    </v-col>

    <v-col cols="12" xl="7" lg="9" md="12" sm="12" xs="12" class="px-0 py-8">
      <!-- DETAILS -->
      <div class="full-width d-flex flex-column justify-space-between align-start">
        <div v-if="loading" class="my-2"></div>
        <!-- main details -->
        <div class="full-width d-flex flex-column justify-start align-start flex-sm-row justify-sm-space-between align-sm-start flex-wrap">
          <!-- name and date -->
          <div class="d-flex flex-column justify-start align-start" >
            <!-- title -->
            <div class="full-width d-flex flex-row align-sm-center align-start" style="position: relative;">
              <v-icon small class="mr-2 mt-2 mt-sm-0 primary--text">mdi-information</v-icon>
              <div v-if="!loading" class="title-text-xl">{{project.title}}</div>
              <div v-else class="loading-data ml-5" style="width: 250px;"></div>
            </div>
            <!-- date -->
            <div  class="full-width d-flex flex-row align-center mt-2" style="position: relative;">
              <v-icon small class="mr-2 primary--text">mdi-calendar</v-icon>
              <div v-if="!loading" class="date-text">{{project.startDate}} - {{project.endDate}}</div>
              <div v-else class="loading-data ml-5" style="width: 150px;"></div>
            </div>

          </div>

          <!-- action button -->
          <div v-if="!loading" class="align-self-end hidden-xs-only">
            <button ref="liveDemoButton" type="button" :disabled="project.demo === null" class="button-primary button-medium" @click="seeLiveDemo()">{{liveDemoText}}</button>
          </div>

          <div v-if="!loading" class="full-width hidden-sm-and-up mt-4">
            <button ref="liveDemoButton" type="button" :disabled="project.demo === null" class="full-width button-primary button-medium" @click="seeLiveDemo()">{{liveDemoText}}</button>
          </div>
        </div>

        <!-- description -->
        <div class="full-width d-flex flex-row align-start my-8" style="position: relative;">
          <!-- <h5>Description</h5> -->
          <v-icon small class="mr-2 mt-1 primary--text">mdi-text</v-icon>
          <div v-if="!loading">{{project.description}}</div>
          <div v-else class="loading-data ml-5" style="height: 40px;" ></div>
        </div>

        <div v-if="loading" class="my-8"></div>
        <!-- columned details -->
        <div  class="d-flex flex-column flex-sm-row justify-sm-center justify-sm-start align-sm-start flex-wrap full-width">
          <v-spacer class="hidden-xs-only"></v-spacer>

          <!-- technologies -->
          <div class="d-flex flex-column justify-start align-start">
            <div class="d-flex flex-row align-center">
              <v-icon small class="mr-2 primary--text">mdi-laptop</v-icon>
              <div class="title-text-sm">Stack</div>
            </div>

            <v-divider class="my-2 primary" style="opacity: .5; width: 170px;"></v-divider>

            <div>
              <ul class="pa-0 pl-3">
                <template v-if="!loading">
                  <li v-for="(s, i) in project.stack" :key="i" class="list-bar">
                    <div class="bar mr-2"></div>
                    {{s}}
                  </li>
                </template>
                <template v-else>
                  <li v-for="i in 7" :key="i" class="list-bar mt-4" style="position: relative;">
                    <div class="bar"></div>
                    <div class="loading-data ml-5" style="width: 150px; height: 10px;"></div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
      
          <v-spacer class="hidden-xs-only"></v-spacer>

           <!-- new stuff -->
          <div class="d-flex flex-column justify-start align-start mt-8 mt-sm-0">

            <div class="d-flex flex-row align-center">
              <v-icon small class="mr-2 primary--text">mdi-head-snowflake-outline</v-icon>
              <div class="title-text-sm">Learned</div>
            </div>

            <v-divider class="my-2 primary" style="opacity: .5; width: 170px;"></v-divider>

            <div>
              <ul class="pa-0 pl-3">
                <template  v-if="!loading">
                  <li v-for="(l, i) in project.learned" :key="i" class="list-bar">
                    <div class="bar mr-2"></div>
                    {{l}}
                  </li>
                </template>
                <template v-else>
                  <li v-for="i in 7" :key="i" class="list-bar mt-4" style="position: relative;">
                    <div class="bar"></div>
                    <div class="loading-data ml-5" style="width: 150px; height: 10px;"></div>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <v-spacer class="hidden-xs-only"></v-spacer>

        </div>
      </div>

      <div v-if="loading" class="my-16"></div>

      <!-- MORE ITEMS -->
      <v-divider class="full-width my-8"></v-divider>
      <h3 class="primary--text hidden-xs-only">More Projects</h3>
      <h5 class="primary--text hidden-sm-and-up">More Projects</h5>

      <div class="d-flex flex-row justify-space-between align-center full-width more-items-container">
        <transition name="overlay-fade">
          <div v-if="showLeftSoftEdge" :class="[$vuetify.theme.isDark ? 'dark-edge' : 'light-edge']" class="soft-edge" id="left-soft-edge"></div>
        </transition>
        <v-icon v-if="showLeftArrow" id="left-arrow" style="z-index: 3;">mdi-chevron-left</v-icon>

        <div id="more-items-list" class="d-flex flex-row justify-start align-start full-width">

          <template v-if="!loading">
            <v-col cols="9" xl="3" lg="3" md="4" sm="4" xs="9" v-for="(item, i) in getSimilarPortfolioItems" :key="item.id" :class="[  i === 0 ? 'pl-0' : i === getSimilarPortfolioItems.length - 1 ? 'pr-0' : '']">
              <ItemCard :item="item" :index="i" :isAdmin="false" :isLink="true" routeName="ViewProject"  ref="itemCard"/>
            </v-col>
          </template>

          <template v-else>
            <v-col cols="9" xl="2" lg="3" md="4" sm="4" xs="9" v-for="i in 5" :key="i" :class="[  i === 0 ? 'pl-0' : i === 4 ? 'pr-0' : '']" style="position: relative; min-height: 200px;">
              <div class="loading-data"></div>
            </v-col>
          </template>
          
        </div>

        <transition name="overlay-fade">
          <div v-if="showRightSoftEdge" :class="[$vuetify.theme.isDark ? 'dark-edge' : 'light-edge']" class="soft-edge" id="right-soft-edge"> </div>
        </transition>
        <v-icon v-if="showRightArrow" id="right-arrow" style="z-index: 3;">mdi-chevron-right</v-icon>
      </div>



    </v-col>
  </v-container>
</template>
<script>
import ItemCard from '../components/ItemCard.vue'
export default {
  name: 'ViewProject',
  path: '/project/:id',
  metaInfo(){
    return {
      title: typeof this.project !== 'undefined' ?  `Portfolio Project - ${this.project.title}` : `Portfolio Project - ${this.$route.params.id}`
    }
  },
  components: {
    ItemCard
  },
  data() {
    return {
      loading: true,
      showLeftArrow: false,
      showRightArrow: true,
      showRightSoftEdge: true,
      showLeftSoftEdge: false,
      projectCarousel: 0, 
      liveDemoText: "See It Live"
    }
  },
  methods: {
    seeLiveDemo(){
      window.open(this.project.demo, '_blank');
    },
    goBack(){
      console.log(this.$router)
    },
    handleItemsScroll(e){
      const firstCard = this.$refs.itemCard[0].$el;
      // console.log("firstcard", firstCard)
      // const lastCard = this.$refs.itemCard[this.$refs.itemCard.length - 1];
      const containerWidth = e.srcElement.clientWidth;
      const totalWidth = e.srcElement.scrollWidth;
      const currentLeft = e.srcElement.scrollLeft;
      // console.log("container width", containerWidth)
      // console.log(totalWidth)
      // if the containerWidth > totalWidth, don't show either, and don't show Soft Edges
      if(containerWidth >= totalWidth){
        this.showRightSoftEdge = false;
        this.showRightArrow = false;
        this.showLeftSoftEdge = false;
        this.showLeftArrow = false;
        console.log("don't show any")
        return;
      }
      // if the currentLeft is > the first elements left side, show the left arrow
      // if left arrow is showing, left soft edge is too.
      if(currentLeft > firstCard.offsetLeft){
        this.showLeftArrow = true;
        this.showLeftSoftEdge = true;
        // console.log("show left arrow")
      }
      else {
        // console.log("do not show left arrow")
        this.showLeftSoftEdge = false;
        this.showLeftArrow = false;
      }
      // if the currentLeft + the containerWidth <= the total scroll elements width, show the right arrow
      // if the right arrow is showing, the right soft edge is too.
      if( (currentLeft + containerWidth) < totalWidth ){
        this.showRightArrow = true;
        this.showRightSoftEdge = true;
      }
      else {
        // console.log("Do not show right arrow")
        this.showRightSoftEdge = false;
        this.showRightArrow = false;
      }
      
    },
    changeCurrentCarouselItemWithIndex(index){
      this.projectCarousel = index;
    },
    addScrollListener(){
      const container = document.querySelector('#more-items-list');
      if(container){
        container.addEventListener("scroll", this.handleItemsScroll);
        const containerWidth = container.clientWidth;
        const totalWidth = container.scrollWidth;
        if(containerWidth >= totalWidth){
          this.showLeftArrow = false;
          this.showRightArrow = false;
          this.showLeftSoftEdge = false;
          this.showRightSoftEdge = false;
        }
      }
    }
  },
  computed: {
    project(){
      this.loading = true;
      const project = this.$store.getters.getProjectById(this.$route.params.id);
      // const project = this.$store.state.portfolioItems.find(i => i.id === this.$route.params.id);
      if(typeof project === 'undefined'){
        this.loading = true;
        return {};
      }
      else {
        this.loading = false;
        return project;
      }
    },
    getSimilarPortfolioItems(){
      return this.$store.getters.getSimilarPortfolioItems(this.$route.params.id);
    },
    getAllProjectImages(){
      const a = [...this.project.images];
      a.unshift({
        src: this.project.img,
        description: this.project.title,
      });
      return a;
    },
    isMobileDevice(){
      return this.$store.state.isMobileDevice;
    }
  },
  watch: {
    // loading(newValue, oldValue){
    //   console.log(oldValue, newValue);
    //   if(!newValue){
    //     setTimeout(() => {
    //       this.$vuetify.goTo(0);

    //       // window.scrollTop = 0;
    //     }, 400);
    //   }
    // },
    project(newValue, oldValue) {
      this.$log(newValue.id, oldValue.id)
      if(newValue !== oldValue){
        this.projectCarousel = 0;
        window.scrollTop = 0;
      }
    }
  },
  created(){
    // console.log("created view project", this.project)
    // console.log("params: ", this.$route.params.id)
  },
  mounted(){
    // console.log(this.$refs.liveDemoButton.hasAttribute('disabled'));
    if(this.$refs.liveDemoButton.hasAttribute('disabled')){
      this.liveDemoText = "Demo Coming Soon..."
    }
    this.addScrollListener();
    // process.nextTick(() => {
    //       window.scrollTop = 0;
    //     });
    
  },
  beforeDestroy() {
    const container = document.querySelector('#more-items-list');
    if(container){
      container.removeEventListener("scroll", this.handleItemsScroll);
    }
  },
  
}
</script>
<style lang="scss">

.project-image-container {
  width: 100%;
  max-width: 100%;
  // height: 35vh;
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 400px;
}

.project-main-img {
  width: 100% !important;
  max-width: 100% !important;
  max-height: 100%;
  // object-fit: contain;
  // object-position: center center;
}

.carousel-delimiter-preview {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  // right: 0;
  // width: 100%;
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .preview-item{
    height: 100%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
    margin-left: 2px;
    transition: all .4s ease;
  }
}

.highlight {
  border: solid .5px rgba(255,255,255,0.2);
}


.go-back-button {
  // position: absolute;
  // top: 0;
  // left: 0;
  z-index: 1;
}

#more-items-list {
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: none !important; 
  min-height: 200px;
}

.more-items-container {
  position: relative;
}

#more-items-list::-webkit-scrollbar{
  display: none !important;
}



.soft-edge {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 50px;
}

#left-soft-edge {
  left: -5px;
  // background: linear-gradient(90deg, var(--v-secondary-base) 30%, rgba(255,255,255,0) 100%);
}

#right-soft-edge {
  right: -5px;
  // background: linear-gradient(-90deg, var(--v-secondary-base) 30%, rgba(255,255,255,0) 100%);
}

#right-soft-edge.dark-edge {
  background: linear-gradient(-90deg, #212121 30%, rgba(33,33,33,0) 100%);
}

#left-soft-edge.dark-edge {
  background: linear-gradient(90deg, #212121 30%, rgba(33,33,33,0) 100%);
}

#right-soft-edge.light-edge {
  background: linear-gradient(-90deg, #E0E0E0 30%, rgba(224,224,224,0) 100%);
}

#left-soft-edge.light-edge {
  background: linear-gradient(90deg, #E0E0E0 30%, rgba(224,224,224,0) 100%);
}

#left-arrow {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateX(-50%);
  z-index: 3;
  opacity: .5;
}

#right-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) translateX(50%);
  z-index: 3;
  opacity: .5;
}

.list-bar {
  font-size: .9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
}
.bar {
  height: 2px;
  width: 8px;
  background: var(--v-primary-base);
  border-radius: 2em;
}



// MEDIA QUERIES

/* XS */
@media (max-width: 600px){
   .project-image-container {
      min-height: 150px;
    }
    // .carousel-delimiter-preview {
    //   height: 25px;
    // }
}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
  .project-image-container {
      min-height: 400px;
    }
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
    .project-image-container {
      min-height: 500px;
    }
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
  .project-image-container {
    min-height: 400px;
  }
  #more-items-list {
    min-height: 200px;
  }
   
}

/* XL */
@media (min-width: 1905px){
  .project-image-container {
    min-height: 500px;
  }
  #more-items-list {
    min-height: 200px;
  }
}



  
</style>